<template lang="pug">
.promotion-banner(v-if="showBanner")
  .promotion-banner-desktop(@click="goTo('oferta', 'start')")
    picture
      source(
        srcset="~/assets/home/promotions/subscription-gift/2025-04-queromar/2025-04-queromar-banner-desktop.webp 1x, ~/assets/home/promotions/subscription-gift/2025-04-queromar/2025-04-queromar-banner-desktop-2x.webp 2x",
        type="image/webp"
      )
      source(
        srcset="~/assets/home/promotions/subscription-gift/2025-04-queromar/2025-04-queromar-banner-desktop.png 1x, ~/assets/home/promotions/subscription-gift/2025-04-queromar/2025-04-queromar-banner-desktop-2x.png 2x",
        type="image/png"
      )
      img(
        src="~/assets/home/promotions/subscription-gift/2025-04-queromar/2025-04-queromar-banner-desktop.png",
      )

  .promotion-banner-mobile(@click="goTo('oferta', 'start')")
    picture
      source(
        srcset="~/assets/home/promotions/subscription-gift/2025-04-queromar/2025-04-queromar-banner-mobile.webp 1x, ~/assets/home/promotions/subscription-gift/2025-04-queromar/2025-04-queromar-banner-mobile-2x.webp 2x",
        type="image/webp"
      )
      source(
        srcset="~/assets/home/promotions/subscription-gift/2025-04-queromar/2025-04-queromar-banner-mobile.png 1x, ~/assets/home/promotions/subscription-gift/2025-04-queromar/2025-04-queromar-banner-mobile-2x.png 2x",
        type="image/png"
      )
      img(
        src="~/assets/home/promotions/subscription-gift/2025-04-queromar/2025-04-queromar-banner-mobile.png",
      )
</template>
<script setup lang="ts">
const route = useRoute()
const router = useRouter()
const posthog = usePostHog()

const useBanner = ref(false)

const show = ref(false)

const showBanner = computed(() => show.value && useBanner.value)

const giftCoupons = ['queromar', 'quemar']

const giftCoupon = computed(() => {
  const coupon = route.query.coupon

  if (coupon) {
    if (Array.isArray(coupon)) {
      return coupon.find(c => c != null && giftCoupons.includes(c.toLowerCase()))
    } else {
      return giftCoupons.includes(coupon.toLowerCase()) ? coupon : null
    }
  }

  return null
})

const hasGiftCoupon = computed(() => {
  const coupon = giftCoupon.value

  if (coupon) {
    return giftCoupons.includes(coupon.toLowerCase())
  }

  return false
})

const goTo = (elementId: string, block: ScrollLogicalPosition = 'center') => {
  router.push({ query: { coupon: 'queromar' } })

  setTimeout(() => {
    document.getElementById(elementId)?.scrollIntoView({
      behavior: "smooth",
      block,
    })
  }, 100)
}

var openTimeout: ReturnType<typeof setTimeout>
onMounted(() => {
  if (hasGiftCoupon.value) {
    show.value = true
  }

  posthog?.onFeatureFlags(() => {
    useBanner.value = posthog?.getFeatureFlag('2025-04-queromar-popup') == 'banner'

    if (!show.value) {
      show.value = posthog?.getFeatureFlag('2025-04-queromar-force-popup') == 'true'
    }
  })
})

onUnmounted(() => {
  clearTimeout(openTimeout)
})
</script>
<style lang="sass">
@import '~/assets/styles/mixins'

.promotion-banner
  display: block
  text-align: center

  .promotion-banner-desktop
    display: inline-block
    cursor: pointer

    @include phablet
      display: none

    @media (min-width: 1441px)
      border-radius: 8px
      overflow: hidden

  .promotion-banner-mobile
    display: none
    cursor: pointer

    @include phablet
      display: inline-block

  picture
    img
      width: 100vw
      max-width: 1440px
      height: auto
      vertical-align: middle
</style>
