<template lang="pug">
BlackFridayHome(v-if="false")

.home(v-else)

  #home-banner
    QueromarPromotionBanner

  Headline
  HowItWorks
  Brands
  Why

  Offer(
    v-if="useOldOffer"
    :subscriptionGiftPromotions="availableSubscriptionGiftPromotions"
  )

  Offer2(
    v-else
    :subscriptionGiftPromotions="availableSubscriptionGiftPromotions"
  )

  Boxes

  CarePromotionPopup
  SallvePromotionPopup
  QueromarPromotionPopup
</template>
<script setup lang="ts">
import type { WatchStopHandle } from "vue"
import type { SubscriptionGiftAvailabilityResponse } from "~/services/subscription/promotion/promotionService"
import PromotionService from "~/services/subscription/promotion/promotionService"

import Boxes from "./Sections/Boxes.vue"
import Brands from "./Sections/Brands.vue"
import Headline from "./Sections/Headline.vue"
import HowItWorks from "./Sections/HowItWorks.vue"
import Offer from "./Sections/Offer.vue"
import Offer2 from "./Sections/Offer2.vue"
import Why from "./Sections/Why.vue"
import CarePromotionPopup from "~/promotions/202502-care/PromotionPopup.vue"
import SallvePromotionPopup from "~/promotions/202502-sallve/PromotionPopup.vue"
import QueromarPromotionBanner from "~/promotions/202504-queromar/PromotionBanner.vue"
import QueromarPromotionPopup from "~/promotions/202504-queromar/PromotionPopup.vue"
import { useActiveSubscription } from "~/store/subscription/subscription"

import BlackFridayHome from './Promotions/2024BlackFriday/Home.vue'

useHead({
  title: "Box Magenta"
})

definePageMeta({
  alias: [ '/oferta' ]
})

const route = useRoute()
const posthog = usePostHog()

const availableSubscriptionGiftPromotions = ref<SubscriptionGiftAvailabilityResponse[]>([])

const useOldOffer = ref(false)

const goToOffer = () => {
  setTimeout(() => {
    document.getElementById("oferta")?.scrollIntoView({
      behavior: "smooth",
    })
  })
}

var experimentsStopHandle: WatchStopHandle | null = null
onMounted(() => {
  if (route.path == "/oferta") {
    goToOffer()
  }

  PromotionService.listAvaiableSubscriptionGiftPromotions().then((response) => {
    availableSubscriptionGiftPromotions.value = response
  }).catch((error) => {
    captureException(new Error('Failed to list available subscription gift promotions', { cause: error }))
  })
})

onMounted(() => {
  posthog?.onFeatureFlags(() => {
    useOldOffer.value = posthog?.getFeatureFlag('home-offer') == 'old'
  })
})

onUnmounted(() => {
  experimentsStopHandle?.apply(this)
})
</script>
<style lang="sass" scoped>
@import '~/assets/styles/mixins'

.home
  margin: 24px 0 0
</style>
