<template lang="pug">
#oferta.offer

  .content
    h2 Escolha o seu plano

    .offer-plans

      NuxtLink#plano-anual.offer-plan(
        :to="offerPath('annually')"
      )

        .melhor-opcao
          img(src="~/assets/home/sections/offer/melhor-opcao.svg")

        .offer-plan--header
          span.offer-plan--title
            | Plano Anual

        .offer-plan--pricing

          .regular-price R$ 169,90

          .price
            span.small R$
            span.bold.large 149,90
            span.small /mês

          .saving
            | Economize R$240

        .offer-plan--description Cobrança anual parcelada

        .offer-plan--cta Eu quero

      NuxtLink#plano-semestral.offer-plan(
        :to="offerPath('biannually')"
        style="background-color: #5bc4f1"
      )
        .offer-plan--header
          span.offer-plan--title
            | Plano Semestral

        .offer-plan--pricing

          .regular-price R$ 169,90

          .price
            span.small R$
            span.bold.large 159,90
            span.small /mês

          .saving
            | Economize R$60

        .offer-plan--description
          | Cobrança semestral parcelada

        .offer-plan--cta Eu quero

      NuxtLink#plano-mensal.offer-plan(
        :to="offerPath('monthly')"
        style="background-color: #7b61ff"
      )
        .offer-plan--header
          span.offer-plan--title
            | Plano Mensal

        .offer-plan--pricing

          .price
            span.small R$
            span.bold.large 169,90
            span.small /mês

        .offer-plan--description
          | Sem fidelidade

        .offer-plan--cta Eu quero

    span.notice Renovação automática em todos os planos

    ClientOnly
      SimpleSubscriptionGiftPopup
</template>
<script setup lang="ts">
import type { PropType } from "vue"
import type { SubscriptionGiftAvailabilityResponse } from "~/services/subscription/promotion/promotionService"

import { planAliasToId } from "~/services/subscription/subscriptionService"
import { useAuth } from "~/store/auth"

import SimpleSubscriptionGiftPopup from "~/pages/Home/Promotions/SubscriptionGift/SimpleSubscriptionGiftPopup.vue"

const props = defineProps({
  subscriptionGiftPromotions: {
    type: Object as PropType<SubscriptionGiftAvailabilityResponse[]>,
    required: true,
    default: []
  }
})

const auth = useAuth()
const route = useRoute()

const offerPath = (plan: string) => {
  const promotion = subscriptionGiftPromotionForPlan(plan)?.ruleId
  const subscriptionGiftId = promotion ?? undefined
  const coupon = route.query.coupon ?? undefined

  const query = {
    plan,
    subscriptionGiftId,
    coupon
  }

  if (auth.isAuthenticated) {
    return { name: 'subscribe', query }
  } else {
    return { name: 'subscription-register', query }
  }
}

const subscriptionGiftPromotionForPlan = (plan: string) => {
  const planId = planAliasToId(plan);
  const promotion = props.subscriptionGiftPromotions.find((promotion) =>
    promotion.planIds.indexOf(planId) >= 0
  );

  return promotion ?? null;
};

const monthlyGiftPromotion = computed(() => subscriptionGiftPromotionForPlan("monthly"));
const biannuallyGiftPromotion = computed(() => subscriptionGiftPromotionForPlan("biannually"));
const annuallyGiftPromotion = computed(() => subscriptionGiftPromotionForPlan("annually"));
</script>

<style lang="sass" scoped>
@import "~/assets/styles/mixins"
@import "~/assets/styles/variables"

.offer
  background-color: $white
  padding: 56px 48px

  .content
    display: flex
    width: 100%
    max-width: 1200px
    margin: 0 auto
    flex-direction: column
    justify-content: center
    align-items: center

    h2
      position: relative
      font-size: 32px
      margin: 0 0 24px
      text-align: center

    .offer-plans
      display: flex
      width: 100%
      flex-direction: row
      justify-items: center
      flex-wrap: wrap
      align-items: stretch
      gap: 16px

      .offer-plan
        position: relative
        display: flex
        flex-direction: column
        justify-content: space-between
        align-items: center
        flex: 1
        gap: 24px
        min-width: 302px
        box-shadow: 0px 25px 65px rgba(0, 0, 0, 0.2)
        border-radius: 32px
        padding: 68px 42px
        text-align: center
        font-family: "Spartan", sans-serif
        background-color: $beige
        color: white
        transform: scale(0.85)

        &#plano-anual
          background-color: $magenta !important
          color: white
          transform: scale(1)

        .melhor-opcao
          position: absolute
          top: 0
          left: 0
          transform: translate(-40%, -40%)

        .offer-plan--header
          position: relative

          span.offer-plan--title
            font-size: 28px
            font-weight: 800
            margin: 0
            text-transform: uppercase
            flex-grow: 1

        .offer-plan--pricing
          display: flex
          flex-direction: column
          gap: 20px

          span.small
            font-size: 28px

          span.large
            font-size: 42px
            line-height: 38px

          span.magenta
            color: $magenta

          .regular-price
            display: inline-block
            position: relative
            font-size: 22px
            line-height: 32px

            &:after
              display: block
              position: absolute
              content: ''
              left: 50%
              top: 50%
              width: 128px
              height: 2px
              background-color: white
              transform: translate(-50%, -50%) rotate(-3deg)

          .detail
            font-size: 18px
            color: $beige

          .saving
            padding: 8px 16px
            border: 2px dashed white
            border-radius: 24px
            font-size: 16px
            font-weight: 800

        span.bold
          font-weight: 800

        span.strikethrough
          text-decoration: line-through

          br.installments-line-break
            display: none

        .offer-plan--gift
          display: flex
          flex-direction: row
          justify-content: center
          align-items: center
          font-size: 12px
          max-width: 240px
          background-color: rgba(0, 0, 0, 0.2)
          padding: 8px 12px
          border-radius: 4px
          gap: 4px
          white-space: pre-line

          .icon
            display: flex
            justify-content: center
            align-items: center
            height: 32px
            width: 32px
            font-size: 16px
            flex-shrink: 0

        .offer-plan--description
          font-size: 18px

        .offer-plan--cta
          position: relative
          display: inline-flex
          padding: 12px 24px
          align-self: center
          font-size: 28px
          font-weight: 800
          line-height: normal
          background-color: white
          border-radius: 32px
          color: $textColor

    span.notice
      margin-top: 32px
      font-size: 20px
      text-align: center
      color: $textColor

@include tablet
  .offer
    padding: 56px 32px

    .content

      .offer-plans
        flex-direction: column

        .offer-plan
          position: relative
          gap: 16px
          padding: 32px 16px
          transform: scale(1)

          .offer-plan--header

            span.offer-plan--title
              font-size: 28px

          .melhor-opcao
            position: absolute
            top: 0
            left: 0
            transform: translate(-30%, -30%)

            img
              width: 96px

          .offer-plan--pricing
            gap: 8px

            .regular-price
              font-size: 16px
              line-height: 24px

              &:after
                width: 96px

            span.small
              font-size: 20px

            span.large
              font-size: 38px

            .detail
              font-size: 14px

          .offer-plan--description
            font-size: 12px

@include mobile
  .offer

    .content
      .offer-plans
        max-height: 100%

        .offer-plan
          transform: scale(1)

          .offer-plan--header
            span.offer-plan--title
              font-size: 28px

          .offer-plan--pricing
            font-size: 18px

            span.small
              font-size: 14px

            span.large
              font-size: 28px

@include extrasmall
  .offer
    padding: 42px 16px

    .content

      h2
        font-size: 24px

      .offer-plans
        max-height: 100%

        .offer-plan
          min-width: 260px
          transform: scale(1)

          .offer-plan--header
            span.offer-plan--title
              font-size: 22px

          .offer-plan--pricing
            font-size: 16px

            span.small
              font-size: 12px

            span.large
              font-size: 22px

          .offer-plan--cta
            padding: 8px 16px
            font-size: 20px
            line-height: 24px
            border-radius: 16px
</style>
