<template lang="pug">
Popup(:open="showPopup" @update:open="open = $event")
  .care-promotion-popup(v-if="showAlternativePopup" @click="goTo('oferta', 'start')")
    picture
      source(
        srcset="~/assets/home/promotions/subscription-gift/2025-02-care/2025-02-care-popup.webp 1x, ~/assets/home/promotions/subscription-gift/2025-02-care/2025-02-care-popup-2x.webp 2x",
        width="640",
        height="640",
        type="image/webp"
      )
      source(
        srcset="~/assets/home/promotions/subscription-gift/2025-02-care/2025-02-care-popup.png 1x, ~/assets/home/promotions/subscription-gift/2025-02-care/2025-02-care-popup-2x.png 2x",
        width="640",
        height="640",
        type="image/png"
      )
      img(
        src="~/assets/home/promotions/subscription-gift/2025-02-care/2025-02-care-popup.png",
        width="640",
        height="640"
      )

  .care-promotion-popup(v-else @click="goTo('oferta', 'start')")
    picture
      source(
        srcset="~/assets/home/promotions/subscription-gift/2025-02-care/2025-02-care-popup-2.webp 1x, ~/assets/home/promotions/subscription-gift/2025-02-care/2025-02-care-popup-2-2x.webp 2x",
        width="640",
        height="640",
        type="image/webp"
      )
      source(
        srcset="~/assets/home/promotions/subscription-gift/2025-02-care/2025-02-care-popup-2.png 1x, ~/assets/home/promotions/subscription-gift/2025-02-care/2025-02-care-popup-2-2x.png 2x",
        width="640",
        height="640",
        type="image/png"
      )
      img(
        src="~/assets/home/promotions/subscription-gift/2025-02-care/2025-02-care-popup-2.png",
        width="640",
        height="640"
      )
</template>
<script setup lang="ts">
const posthog = usePostHog()

const route = useRoute()

const open = ref(false)
const featureFlagsLoaded = ref(posthog == undefined)

const giftCoupons = ['oicare']

const showPopup = computed(() => {
  return open.value && featureFlagsLoaded.value
})

const giftCoupon = computed(() => {
  const coupon = route.query.coupon

  if (coupon) {
    if (Array.isArray(coupon)) {
      return coupon.find(c => c != null && giftCoupons.includes(c.toLowerCase()))
    } else {
      return giftCoupons.includes(coupon.toLowerCase()) ? coupon : null
    }
  }

  return null
})

const hasGiftCoupon = computed(() => {
  const coupon = giftCoupon.value

  if (coupon) {
    return giftCoupons.includes(coupon.toLowerCase())
  }

  return false
})

const showAlternativePopup = computed(() => {
  return posthog?.getFeatureFlag('2025-02-care-popup-alternative') == 'alternative'
})

const goTo = (elementId: string, block: ScrollLogicalPosition = 'center') => {
  open.value = false

  document.getElementById(elementId)?.scrollIntoView({
    behavior: "smooth",
    block,
  })
}

var openTimeout: ReturnType<typeof setTimeout>
onMounted(() => {
  if (hasGiftCoupon.value) {
    open.value = true
  }

  posthog?.onFeatureFlags(() => {
    featureFlagsLoaded.value = true
  })
})

onUnmounted(() => {
  clearTimeout(openTimeout)
})
</script>
<style lang="sass">
.care-promotion-popup

  picture
    img
      width: 100%
      height: auto
      vertical-align: middle
</style>
